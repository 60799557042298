import { applyMiddleware, createStore } from "@reduxjs/toolkit";
import Reducers from "./Redux/Reducers";
// import { composeWithDevTools } from "@redux-devtools/extension/lib/types/logOnly";
import { thunk } from "redux-thunk";

// export default configureStore({
//   reducer: Reducers,
//   middleware: () => {applyMiddleware(thunk)}
// });

export default createStore(Reducers, applyMiddleware(thunk));
