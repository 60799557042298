import * as React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "grey",
};

export default function Loader() {
  return (
    <div className="flex items-center content-center w-screen h-screen">
      <ClipLoader
        color={"#fff"}
        loading={true}
        cssOverride={override}
        size={80}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}
