// export const MODE = "production";
// export const MODE = "production"

// export const API_URL = 'https://w2w.ayanshtechnology.com/api';

// export const API_URL =
//   MODE === "production"
//     ? "https://portal.womentowomentv.com/api"
//     : "https://w2w.ayanshtechnology.com/api";

export const API_URL = process.env.REACT_APP_BASE_URL
