import { axiosInstance } from "../../Axios";
import LocalStorage from "../../Data/LocalStorage";
import {
  GET_USER,
  GET_USER_ERROR,
  GET_USER_SUCCESS,
} from "../Constants/UserConstants";

export const getUser = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_USER,
      });

      const response = await axiosInstance.get("/profile", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      dispatch({
        type: GET_USER_SUCCESS,
        user: response.data,
      });
    } catch (error) {
      LocalStorage.remove("token");
      dispatch({ type: GET_USER_ERROR, error: error.response });
    }
  };
};
