export const GET_HOME_SLIDER = "GET_HOME_SLIDER"
export const GET_HOME_SLIDER_SUCCESS = "GET_HOME_SLIDER_SUCCESS"
export const GET_HOME_SLIDER_ERROR = "GET_HOME_SLIDER_ERROR"

export const GET_HOME_LIST = "GET_HOME_LIST"
export const GET_HOME_LIST_SUCCESS = "GET_HOME_LIST_SUCCESS"
export const GET_HOME_LIST_ERROR = "GET_HOME_LIST_ERROR"

export const GET_MOVIE_LIST = "GET_MOVIE_LIST"
export const GET_MOVIE_LIST_SUCCESS = "GET_MOVIE_LIST_SUCCESS"
export const GET_MOVIE_LIST_ERROR = "GET_MOVIE_LIST_ERROR"

export const GET_TV_SHOW_LIST = "GET_TV_SHOW_LIST"
export const GET_TV_SHOW_LIST_SUCCESS = "GET_TV_SHOW_LIST_SUCCESS"
export const GET_TV_SHOW_LIST_ERROR = "GET_TV_SHOW_LIST_ERROR"

export const GET_SINGLE_SHOW_LIST = "GET_SINGLE_SHOW_LIST"
export const GET_SINGLE_SHOW_LIST_SUCCESS = "GET_SINGLE_SHOW_LIST_SUCCESS"
export const GET_SINGLE_SHOW_LIST_ERROR = "GET_SINGLE_SHOW_LIST_ERROR"

export const GET_MORE_LIKE_THIS_LIST = "GET_MORE_LIKE_THIS_LIST"
export const GET_MORE_LIKE_THIS_LIST_SUCCESS = "GET_MORE_LIKE_THIS_LIST_SUCCESS"
export const GET_MORE_LIKE_THIS_LIST_ERROR = "GET_MORE_LIKE_THIS_LIST_ERROR"

export const GET_SUBSCRIPTION_LIST = "GET_SUBSCRIPTION_LIST"
export const GET_SUBSCRIPTION_LIST_SUCCESS = "GET_SUBSCRIPTION_LIST_SUCCESS"
export const GET_SUBSCRIPTION_LIST_ERROR = "GET_SUBSCRIPTION_LIST_ERROR"

export const GET_PACKAGE_LIST = "GET_PACKAGE_LIST"
export const GET_PACKAGE_LIST_SUCCESS = "GET_PACKAGE_LIST_SUCCESS"
export const GET_PACKAGE_LIST_ERROR = "GET_PACKAGE_LIST_ERROR"

export const GET_SEARCH_RESULTS = "GET_SEARCH_RESULTS";
export const GET_SEARCH_RESULTS_SUCCESS = "GET_SEARCH_RESULTS_SUCCESS";
export const GET_SEARCH_RESULTS_ERROR = "GET_SEARCH_RESULTS_ERROR";

export const GET_WATCH_LIST = "GET_WATCH_LIST"
export const GET_WATCH_LIST_SUCCESS = "GET_WATCH_LIST_SUCCESS"
export const GET_WATCH_LIST_ERROR = "GET_WATCH_LIST_ERROR"

export const GET_WATCH_LIST_ID = "GET_WATCH_LIST_ID"
export const GET_WATCH_LIST_SUCCESS_ID = "GET_WATCH_LIST_SUCCESS_ID"
export const GET_WATCH_LIST_ERROR_ID = "GET_WATCH_LIST_ERROR_ID"

export const ADD_TO_WATCH_LIST = "ADD_TO_WATCH_LIST";
export const ADD_TO_WATCH_LIST_SUCCESS = "ADD_TO_WATCH_LIST_SUCCESS";
export const ADD_TO_WATCH_LIST_ERROR = "ADD_TO_WATCH_LIST_ERROR";

export const SAVE_CONTINUE_WATCHING = 'SAVE_CONTINUE_WATCHING';
export const SAVE_CONTINUE_WATCHING_SUCCESS = 'SAVE_CONTINUE_WATCHING_SUCCESS';
export const SAVE_CONTINUE_WATCHING_ERROR = 'SAVE_CONTINUE_WATCHING_ERROR';

export const FETCH_CONTINUE_WATCHING = 'FETCH_CONTINUE_WATCHING';
export const FETCH_CONTINUE_WATCHING_SUCCESS = 'FETCH_CONTINUE_WATCHING_SUCCESS';
export const FETCH_CONTINUE_WATCHING_ERROR = 'FETCH_CONTINUE_WATCHING_ERROR';

export const SAVE_DEVICE_DETAILS = "SAVE_DEVICE_DETAILS"
export const SAVE_DEVICE_DETAILS_SUCESS = "SAVE_DEVICE_DETAILS_SUCESS"
export const SAVE_DEVICE_DETAILS_ERROR = "SAVE_DEVICE_DETAILS_ERROR"

export const PROFILE_UPDATE = "PROFILE_UPDATE"
export const PROFILE_UPDATE_SUCCESS = "PROFILE_UPDATE_SUCCESS"
export const PROFILE_UPDATE_ERROR = "PROFILE_UPDATE_ERROR"

export const PROFILE_AVATAR_UPDATE = "PROFILE_AVATAR_UPDATE"
export const PROFILE_AVATAR_UPDATE_SUCCESS = "PROFILE_AVATAR_UPDATE_SUCCESS"
export const PROFILE_AVATAR_UPDATE_ERROR = "PROFILE_AVATAR_UPDATE_ERROR"

export const UPDATE_PASSWORD = "UPDATE_PASSWORD"
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS"
export const UPDATE_PASSWORD_ERROR = "UPDATE_PASSWORD_ERROR"

export const GET_PLATFORM_LIST = "GET_PLATFORM_LIST"
export const GET_PLATFORM_LIST_SUCCESS = "GET_PLATFORM_LIST_SUCCESS"
export const GET_PLATFORM_LIST_ERROR = "GET_PLATFORM_LIST_ERROR" 